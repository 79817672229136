<template>
	<standard-container title="competenze" description="" menuicon="competenze" nocard>
		<v-card>
			<v-card-text>
				<div class="d-block d-md-flex align-baseline">
					<v-text-field ref="searchField" v-model="search" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" autofocus prepend-inner-icon="mdi-magnify" :label="gTranslate('Ricerca')" single-line hide-details clear-icon="mdi-close-circle" clearable />
					<v-switch v-model="visualizzaNascosti" inset :label="gTranslate('Visualizza eliminati')" color="warning" class="ml-0 ml-md-10" />
				</div>
			</v-card-text>
		</v-card>
		<v-row>
			<v-col cols="12">
				<v-expansion-panels :value="openedPanels" :multiple="search!==''">
					<template v-for="tipoCompetenza in tipoCompetenze">
						<v-expansion-panel :key="tipoCompetenza.Id" class="elevation-2" :class="tipoCompetenza.Visibile ? 'd-block' : 'd-none'"> 
							<!-- :class="competenzePerTipo(tipoCompetenza.CodiceTipoCompetenza).length>0 ? 'd-block' : 'd-none'" -->
							<v-expansion-panel-header class="headline warning--text">
								{{ tipoCompetenza.DescrizioneTipoCompetenza }}
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="d-flex justify-end">
									<v-btn small color="warning" @click="editCompetenza(null, tipoCompetenza)">
										<v-icon left>
											mdi-plus
										</v-icon><translated-text>Aggiungi</translated-text>
									</v-btn>
								</div>
								<v-list two-line>
									<template v-for="competenza in competenzePerTipo(tipoCompetenza.CodiceTipoCompetenza)">
										<v-list-item :key="competenza.Id" @click="editCompetenza(competenza, tipoCompetenza)">
											<v-list-item-content>
												<v-list-item-title>{{ competenza.NomeCompetenza }}</v-list-item-title>
												<v-list-item-subtitle>{{ competenza.DescrizioneCompetenza }}</v-list-item-subtitle>
											</v-list-item-content>
											<v-list-item-action>
												<v-list-item-action-text>
													<v-icon v-if="!competenza.Visibile" :title="gTranslate('Non visibile')" color="warning">
														mdi-eye-off
													</v-icon>
													<span class="mx-4">{{ competenza.CodiceCompetenza }}</span>
													<v-btn v-if="competenza.Visibile" text icon x-small color="warning" :title="gTranslate('Elimina competenza')" @click.stop="deleteItem(competenza)">
														<v-icon>mdi-delete</v-icon>
													</v-btn>
													<v-btn v-else text icon x-small color="success" :title="gTranslate('Ripristina competenza eliminata')" @click.stop="undeleteItem(competenza)">
														<v-icon>mdi-delete-restore</v-icon>
													</v-btn>
												</v-list-item-action-text>
											</v-list-item-action>
										</v-list-item>
										<v-divider :key="competenza.Id + 'div'" />
									</template>
								</v-list>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</template>
				</v-expansion-panels>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-btn small icon :title="gTranslate('Ricarica la lista dal server')" :disabled="loading" :loading="loading" @click="refresh">
					<v-icon>mdi-refresh</v-icon>
				</v-btn>
			</v-col>
		</v-row>

		<debug-container>
			<v-row>
				<v-col cols="6">
					<debug-panel label="decodifiche.TipoCompetenze">
						{{ tipoCompetenze }}
					</debug-panel>
				</v-col>
				<v-col cols="6">
					<debug-panel label="competenze">
						{{ competenze }}
					</debug-panel>
				</v-col>
			</v-row>
		</debug-container>
		<!-- inizio dialog -->
		<v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="600" @keydown.esc="dialog = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor" dark>
					<v-btn icon @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ currentCompetenza.Id>0 ? gTranslate('Modifica competenza'): gTranslate('Nuova competenza') }} - {{ currentTipoCompetenza.DescrizioneTipoCompetenza }}</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="!currentCompetenza.NomeCompetenza || saving" :loading="saving" @click="salvaCompetenza">
							{{ gTranslate('Salva') }}
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row :dense="compactVisualization">
						<v-col cols="12" lg="12">
							<v-text-field ref="autofocus" v-model="currentCompetenza.NomeCompetenza" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Nome')" />
						</v-col>
						<v-col cols="12" lg="12">
							<v-textarea v-model="currentCompetenza.DescrizioneCompetenza" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Descrizione')" rows="6" auto-grow />
						</v-col>
					</v-row>
					<v-row v-if="error" dense>
						<v-col cols="12">
							<v-alert text type="error">
								<span class="title">{{ error }}</span><br><span class="caption">{{ errorDetail }}</span>
							</v-alert>
						</v-col>
					</v-row>

					<debug-container>
						<v-row :dense="compactVisualization">
							<v-col cols="6">
								<debug-panel label="currentTipoCompetenza">
									{{ currentTipoCompetenza }}
								</debug-panel>
							</v-col>
							<v-col cols="6">
								<debug-panel label="currentCompetenza">
									{{ currentCompetenza }}
								</debug-panel>
							</v-col>
						</v-row>
					</debug-container>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog -->			
	</standard-container>
</template>

<script>
import StandardContainer from '@/components/standard-container'
import DebugPanel from '@/components/debug-panel'
import DebugContainer from '@/components/debug-container'
import lodash from 'lodash'
export default {
	name: 'CompetenzePage',
	components: { StandardContainer, DebugContainer, DebugPanel },
	data: () => ({
		search: '',
		dialog: false,
		saving: false,
		loading: false,
		error: '',
		errorDetail: '',
		currentTipoCompetenza: {},
		currentCompetenza: {},
		visualizzaNascosti: false,
	}),
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		competenze() { return this.$store.getters['competenze'] },
		// genera una struttura specifica (array da 0 Npannelli-1) per aprire tutti i pannelli quando si effettua una ricerca 
		openedPanels() {
			if(this.search) {
				var outList = []
				for(var ii=0; ii<this.tipoCompetenze.length; ii++){
					outList.push(ii)
				}
				return outList
			}
			return null
		},
		tipoCompetenze() { 
			return this.decodifiche.TipoCompetenze 
		},
	},
	mounted() {
		this.$refs.searchField.focus()
	},
	methods: {
		competenzePerTipo(codiceTipoCompetenza) {
			if(this.search) {
				return lodash.orderBy(lodash.filter(this.competenze, item => {
					var a = item.CodiceTipoCompetenza === codiceTipoCompetenza
					var b = item.NomeCompetenza && item.NomeCompetenza.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
					var c = item.DescrizioneCompetenza && item.DescrizioneCompetenza.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
					var d = item.CodiceCompetenza && item.CodiceCompetenza.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
					var visible = item.Visibile || this.visualizzaNascosti
					return a && (b || c || d) && visible
				}), 'NomeCompetenza')
			} else {
				if(this.visualizzaNascosti)
					return lodash.orderBy(lodash.filter(this.competenze, {'CodiceTipoCompetenza': codiceTipoCompetenza}), 'NomeCompetenza')
				else
					return lodash.orderBy(lodash.filter(this.competenze, {'CodiceTipoCompetenza': codiceTipoCompetenza, 'Visibile': true}), 'NomeCompetenza')
			}
		},
		// apre la modale è imposta l'autofocus
		openDialog() {
			this.dialog = true
			setTimeout(() => {
				var afdummy = this.$refs.autofocus
				if (afdummy) afdummy.focus()
			}, 300)
		},
		// aggiungi / modifica competenza
		async editCompetenza(competenza, tipoCompetenza) {
			if(!competenza) {
				var negativeId = 0
				await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id }) // chiamata sincrona allo store !
				competenza = {
					Id: negativeId,
					NomeCompetenza: '', 
					DescrizioneCompetenza: '',
					CodiceTipoCompetenza: tipoCompetenza.CodiceTipoCompetenza 
				}
			}
			this.currentTipoCompetenza =  JSON.parse(JSON.stringify(tipoCompetenza))
			this.currentCompetenza = JSON.parse(JSON.stringify(competenza))
			this.openDialog()
		},
		// salva competenza nuova / modificata
		salvaCompetenza () {
			this.error = ''
			this.saving = true
			this.$store.dispatch('salvaCompetenza', this.currentCompetenza).then(() => {
				this.saving = false
				this.loading = false
				this.dialog = false
			}).catch(error => {
				console.error(error)
				this.error = 'Errore durante il salvataggio di una competenza'
				this.errorDetail = error
				this.loading = false
				this.saving = false
			}) 
		},
		// forza il ricaricamento della lista dal server
		refresh() {
			this.loading = true
			this.$store.dispatch('refreshCompetenze').then(()=>{
				this.loading = false
			})
		},
		deleteItem(item) {
			let swTitle =  this.gTranslate('ATTENZIONE!', {section: 'swal'})
			let swYes =  this.gTranslate('Si', {section: 'swal'})
			let swNo =  this.gTranslate('No', {section: 'swal'})
			let swText =  this.gTranslate('Sei sicuro di voler cancellare')
			this.$swal(swTitle, swText +' "'+item.NomeCompetenza+'" ?', 'warning', {
				buttons: {
					ok: swYes,
					cancel: swNo
				}
			}).then(result => {
				if (result === 'ok') {
					this.loading = true
					this.$store.dispatch('deleteCompetenza', item).then(()=>{
						this.loading = false
					})
				}
			}).catch((error) => {
				console.error(error)
			})
		},
		undeleteItem(item) {
			this.loading = true
			this.$store.dispatch('undeleteCompetenza', item).then(()=>{
				this.loading = false
			})
		},		
	}

}
</script>
